import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebook , faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (<div>
    <div className="footer">
      <div className="footer-column">
        <h4><i class="fa fa-phone" ></i> Zadzwoń</h4>
        {/*<hr className="hr"></hr>*/}
        <p className="footer-napisy">691 956 866</p>
      </div>
      <div className="footer-column">
        <h4><i class="fa fa-envelope"></i> Napisz</h4>
        {/*<hr className="hr"></hr>*/}
        <p className="footer-napisy">kontakt@siedliskowsloncu.pl</p>
      </div>
      <div className="footer-column">
        <h4><i class="fa fa-thumbs-up"></i> Nasze social media</h4>
        {/*<hr className="hr"></hr>*/}
        <p className="footer-napisy"><a href="https://www.facebook.com/profile.php?id=100094629690454"  id="p-fa"> <FontAwesomeIcon icon={faFacebook} size = '2x'  /></a> Siedlisko w Słońcu Podlasia</p>
        <p className="footer-napisy"><a href="https://www.instagram.com/siedliskowsloncu/"  id="p-fa"><FontAwesomeIcon icon={faInstagram} size = '2x'  /></a> siedliskowsloncu</p>
      </div>
      <div className="footer-column">
        <h4> <i class="fa fa-location-arrow"></i> Odwiedź nas</h4>
        {/* <hr className="hr"></hr> */}
        
        <p className="footer-napisy">Wojszki 1</p>
        <p className="footer-napisy">19-100 Mońki </p>
        <p className="footer-napisy">Woj. Podlaskie </p>
      </div>
    </div> 
    <div className="copyright">
        <h6 className="h6">Copyright &copy; 2024 Siedlisko w Słońcu Podlasia</h6>
      </div> 
      
      </div>
  );
};
export default Footer;